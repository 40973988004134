import React from "react"
import { graphql } from "gatsby"
import { Trans } from "react-i18next"

export default function Index() {
  return (
    <p className="flex flex-col py-20 justify-center items-center w-full max-w-md">
      <p className="text-md uppercase font-bold">
        {" "}
        <Trans i18nKey="404.text">Ops... Page not found</Trans>
      </p>
    </p>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
